import React from "react"

import PageTransition from "./PageTransition"
import Header from "./Header"
import Footer from "./Footer"

const Layout = ({ children, hideFooter = false }) => (
  <div className="flex flex-col min-h-screen">
    <PageTransition />
    <Header />
    <main className="flex-grow">{children}</main>
    {!hideFooter && <Footer />}
  </div>
)

export default Layout
