import React from "react"
import { Link } from "gatsby"
import { motion } from "framer-motion"

const variants = {
  initial: {
    opacity: 0,
    translateY: 15,
  },
  enter: {
    opacity: 1,
    translateY: 0,
    transition: {
      delay: 0.75,
      duration: 0.3,
      ease: "easeInOut",
    },
  },
}

const Header = () => (
  <header>
    <motion.div
      className="pt-6 px-6 xl:pt-20 xl:px-20 flex items-center flex-wrap"
      initial="initial"
      animate="enter"
      variants={variants}
    >
      <p className="font-medium text-xl flex-grow w-full sm:w-auto select-none">
        <Link to="/">Forist.</Link>
      </p>
      <ul className="flex space-x-10 mt-4 sm:mt-0 overflow-auto">
        <li>
          <Link
            to="/"
            className="inline-block text-gray-400 transition duration-300 hover:text-black border-b-4 border-transparent hover:border-blue-100"
            activeClassName="text-black border-b-4 border-blue-100"
          >
            Home
          </Link>
        </li>
        <li>
          <Link
            to="/about"
            className="inline-block text-gray-400 transition duration-300 hover:text-black border-b-4 border-transparent hover:border-blue-100"
            activeClassName="text-black border-b-4 border-blue-100"
          >
            About
          </Link>
        </li>
        <li>
          <Link
            to="/news"
            className="inline-block text-gray-400 transition duration-300 hover:text-black border-b-4 border-transparent hover:border-blue-100"
            activeClassName="text-black border-b-4 border-blue-100"
          >
            News
          </Link>
        </li>
        <li>
          <Link
            to="/contact"
            className="inline-block text-gray-400 transition duration-300 hover:text-black border-b-4 border-transparent hover:border-blue-100"
            activeClassName="text-black border-b-4 border-blue-100"
          >
            Contact
          </Link>
        </li>
      </ul>
    </motion.div>
  </header>
)

export default Header
