import React from "react"
import { motion } from "framer-motion"

const variants = {
  hidden: {
    translateY: 0,
  },
  visible: {
    translateY: "-100%",
    transition: {
      duration: 0.5,
      ease: "easeIn",
    },
  },
  exit: {
    translateY: 0,
    transition: {
      duration: 0.8,
      ease: "easeOut",
    },
  },
}

const PageTransition = () => {
  return (
    <motion.div
      className="fixed top-0 left-0 w-screen h-screen bg-blue-100 z-[3]"
      initial="hidden"
      animate="visible"
      exit="exit"
      variants={variants}
    />
  )
}

export default PageTransition
