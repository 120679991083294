import React from "react"
import { Link } from "gatsby"

const Footer = () => (
  <footer>
    <div className="p-6 xl:p-20 grid grid-cols-1 lg:grid-cols-4 gap-6 lg:gap-2">
      <div>
        <h1 className="font-medium text-xl select-none">
          <Link to="/">Forist.</Link>
        </h1>
      </div>
      <div className="space-y-6">
        <div className="space-y-1">
          <p className="text-sm text-gray-400">Careers</p>
          <p>jobs@forist.com</p>
        </div>
        <div className="space-y-1">
          <p className="text-sm text-gray-400">Business inquiries</p>
          <p>contact@forist.com</p>
        </div>
      </div>
      <div className="text-sm text-gray-400 space-y-6">
        <div className="space-y-1">
          <p>Strada Investitiei 29</p>
          <p>1150 Bucharest</p>
          <p>Romania</p>
        </div>
        <div className="space-y-1">
          <p>Phone</p>
          <p>+(222) 123 4352</p>
        </div>
      </div>
      <div className="flex flex-col space-y-1 items-start lg:items-end">
        <ul className="flex space-x-6">
          <li>Instagram</li>
          <li>LinkedIn</li>
          <li>Twitter</li>
        </ul>
        <p className="text-sm text-gray-400 space-y-6">
          &copy; 2021 Forist. Based on Liquet.
        </p>
      </div>
    </div>
  </footer>
)

export default Footer
